import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";

export const getSupabaseErrorMessage = (
  error?: FetchBaseQueryError | SerializedError
) => {
  if (!error) {
    return null;
  }

  if ("message" in error) {
    return error.message;
  }

  if ("error" in error) {
    return error.error;
  }

  return null;
};
