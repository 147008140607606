import { createSelector } from "@reduxjs/toolkit";
import { rtkSubscriptionApi } from "./rtkSubscription";
import { DEFAULT_QUESTIONS_PER_DAY } from "./constants";

export const selectUserSubscription =
  rtkSubscriptionApi.endpoints.getUserSubscriptionSettings.select(undefined);

export const selectServerDate =
  rtkSubscriptionApi.endpoints.getServerDate.select(undefined);

export const selectAllowedQuestionsPerDay = createSelector(
  selectUserSubscription,
  (subscription) =>
    subscription.data?.subscription?.plan?.settings?.questions_per_day ||
    DEFAULT_QUESTIONS_PER_DAY
);
