import { Switch, Stack, FormControlLabel } from "@meetin/uicore";
import { useSelector } from "react-redux";
import { ChangeEvent } from "react";
import { selectSearchAllPages } from "../redux/askLayerSelectors";
import { toggleUserPreference } from "../../../app";

const WebsiteSearcherToggle = () => {
  const checked = useSelector(selectSearchAllPages);

  const handleChange = (
    _e: ChangeEvent<HTMLInputElement>,
    isChecked: boolean
  ) => {
    toggleUserPreference("searchAllPages", isChecked);
  };
  return (
    <Stack sx={{ float: "right" }}>
      <FormControlLabel
        sx={{
          mr: 1,
        }}
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Enable Browsing"
      />
    </Stack>
  );
};

export default WebsiteSearcherToggle;
