import { useForm, Controller } from "react-hook-form";
import {
  Stack,
  Box,
  showErrorNotification,
  StyleMentionInput,
  LoadingButton,
} from "@meetin/uicore";
import { useSelector, useDispatch } from "react-redux";
import {
  KeyboardEvent as ReactKeyboardEvent,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { Mention, MentionsInput } from "react-mentions";
import { Collection, Post, rtkApi } from "@meetin/shared";
import { ReturnKeyIcon } from "@meetin/uicore/icons";
import {
  getUrlWithoutHash,
  selectChannels,
  selectMembers,
  formatTags,
  SupabaseClientHelper,
  trackAction,
  Actions,
  trackActionStart,
  trackActionEnd,
  CollectionsInPost,
  trackEvent,
  AnalyticsEvents,
  hasChannelTag,
  hasUserTag,
  getOGDataFromTab,
  clientLogger,
  ComponentsContext,
} from "../..";

export type NewPostInputs = {
  comment: string;
};

type Props = {
  isSidePanel?: boolean;
  newPostEvent: Partial<Post>;
  uploadImage?: () => Promise<string | undefined | null>;
  togglePosts?: (state: boolean) => void;
  preSubmit?: (
    tempNewPostEvent: Partial<Post>,
    data: NewPostInputs
  ) => string | null;
  afterSubmit: (
    pendingPostId: string | null,
    incomingPostId: string | null,
    postData: Post | null
  ) => void;
  onClose: () => void;
  hideCollectionsSelection?: boolean;
  ignorePageUrl?: boolean;
  // TODO: this no longer needs to be an array
  collections?: Collection[];
};
const PostTextForm = ({
  isSidePanel = false,
  newPostEvent,
  uploadImage,
  togglePosts,
  preSubmit,
  afterSubmit,
  onClose,
  hideCollectionsSelection,
  ignorePageUrl,
  collections = [],
}: Props): JSX.Element | null => {
  // TODO: this no longer needs to be an array
  const [selectedCollections, setSelectedCollections] =
    useState<Collection[]>(collections);

  const { user, currentPageUrl } = useContext(ComponentsContext);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<NewPostInputs>({
    defaultValues: { comment: "" },
  });
  const userId = user?.user_id;
  const dispatch = useDispatch();
  const members = useSelector(selectMembers);
  const channels = useSelector(selectChannels);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isInputEnabled, setIsInputEnabled] = useState(false);

  useEffect(() => {
    if (isInputEnabled) {
      inputRef.current?.focus();
    }
  }, [isInputEnabled]);

  useEffect(() => {
    if (!newPostEvent) {
      reset();
      setIsInputEnabled(false);
    }
  }, [newPostEvent, reset]);

  const onFormSubmit = async (data: NewPostInputs) => {
    try {
      const supabaseClient = SupabaseClientHelper.getSupabaseClient();
      trackAction(Actions.COMMENT_SUBMIT_CLICKED, {});

      if (!newPostEvent) {
        trackAction(Actions.COMMENT_SUBMIT_NO_ANCHOR, {});
        showErrorNotification({ message: "Unable to infer the anchor text" });
        return;
      }

      const pendingPostId = preSubmit?.(newPostEvent, data) ?? null;
      reset();

      trackActionStart(Actions.COMMENT_SUBMIT_API);
      const imageName = await uploadImage?.();
      const basePost = {
        collections: selectedCollections
          .map((collection) => collection.id)
          .join(","),
        ...newPostEvent,
        text: formatTags(data.comment),
      };

      const postData = ignorePageUrl
        ? basePost
        : {
            ogData: await getOGDataFromTab(),
            ...basePost,
            page_url:
              isSidePanel && Boolean(basePost.highlight)
                ? basePost.page_url
                : getUrlWithoutHash(currentPageUrl),
          };
      if (imageName) {
        postData.image_path = imageName;
      }
      clientLogger.info("new post send api call", { postData });

      const { data: newPostData, error } =
        await supabaseClient.functions.invoke<{
          post: Post;
        }>("create-post", {
          body: postData,
        });

      if (error) {
        clientLogger.error(
          "new post api call error",
          undefined,
          error as Error
        );
        trackActionEnd(
          Actions.COMMENT_SUBMIT_API,
          Actions.COMMENT_SUBMIT_FAIL,
          {
            error: error.message,
          }
        );
        showErrorNotification({ message: error.message });
        afterSubmit(pendingPostId, null, null);
        return;
      }

      togglePosts?.(true);

      if (!newPostData) {
        clientLogger.error("new post returned as null");
        showErrorNotification({ message: "Please try again later." });
        return;
      }

      dispatch(rtkApi.util.invalidateTags(["getPostsInPageByUserId"]));

      clientLogger.info("new post created", { post: newPostData.post });

      trackActionEnd(
        Actions.COMMENT_SUBMIT_API,
        Actions.COMMENT_SUBMIT_SUCCESS,
        {
          commentId: newPostData.post.id,
        }
      );

      trackEvent(AnalyticsEvents.COMMENT_ADDED);
      if (hasChannelTag(newPostData.post.text)) {
        trackEvent(AnalyticsEvents.TAGGED_CHANNEL_COMMENT_ADDED);
      }
      if (hasUserTag(newPostData.post.text)) {
        trackEvent(AnalyticsEvents.TAGGED_USER_COMMENT_ADDED);
      }

      setTimeout(() => {
        afterSubmit(pendingPostId, newPostData.post.id, newPostData.post);
      }, 500);
    } catch (error) {
      clientLogger.error(
        "Unable to submit new post",
        undefined,
        error as Error
      );
      showErrorNotification({ message: (error as Error).message });
    }
  };

  const onKeyDown = (
    e:
      | ReactKeyboardEvent<HTMLInputElement>
      | ReactKeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        handleSubmit((data) => onFormSubmit(data))();
      }
    }

    if (e.key === "Escape") {
      onClose();
    }
  };

  const onAdd = (id: string | number, display: string) => {
    clientLogger.debug(`[newpost] on mention select`, { id, display });
  };

  const onCollectionSelect = (collections: Collection[]) => {
    setSelectedCollections(collections);
  };

  const onChipDelete = () =>
    setSelectedCollections((collections) => collections.slice(1));

  // fix for https://linear.app/layer-app/issue/DEV-390/fix-posting-comment
  const onInputRefChange = useCallback((node: HTMLInputElement | null) => {
    if (node !== null) {
      inputRef.current = node;

      setTimeout(() => {
        setIsInputEnabled(true);
      }, 0);
    }
  }, []);

  if (!userId) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit((data) => onFormSubmit(data))}>
      <Stack spacing={1} mt={1}>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <MentionsInput
              style={StyleMentionInput}
              inputRef={onInputRefChange}
              {...field}
              placeholder="Add a note, or tag with '@' or '#' (optional)"
              className="new-post"
              onKeyDown={onKeyDown}
              disabled={!isInputEnabled}
            >
              <Mention
                displayTransform={(id, display) => `@${display}`}
                trigger="@"
                data={Object.values(members)}
                appendSpaceOnAdd
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <div>{highlightedDisplay}</div>
                )}
                onAdd={onAdd}
              />
              <Mention
                appendSpaceOnAdd
                displayTransform={(id, display) => `#${display}`}
                markup="#[__display__](__id__)"
                trigger="#"
                data={Object.values(channels)}
                onAdd={onAdd}
              />
            </MentionsInput>
          )}
        />

        {/* Bottom Bar */}
        <Box display="flex" justifyContent="space-between">
          {!hideCollectionsSelection ? (
            <CollectionsInPost
              selectedCollections={selectedCollections}
              userId={userId}
              onCollectionSelect={onCollectionSelect}
              onChipDelete={onChipDelete}
              isPostOwner
            />
          ) : null}
          <LoadingButton
            loading={isSubmitting}
            size="small"
            type="submit"
            variant="contained"
            endIcon={
              <ReturnKeyIcon style={{ width: "14px", opacity: "60%" }} />
            }
          >
            Done
          </LoadingButton>
        </Box>
      </Stack>
    </form>
  );
};

export default PostTextForm;
