import {
  AUTH_EVENT_NAME,
  Profile,
  SLACK_CHANNELS,
  SLACK_MEMBERS,
  SupabaseClientType,
  SlackChannel,
  SlackMember,
  WEB_MAIN_ELEMENT_ID,
} from "@meetin/shared";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Session } from "@supabase/supabase-js";
import {
  ANONYMOUS_TOKEN,
  ANONYMOUS_USER_ID,
  ComponentsProvider,
  SupabaseClientHelper,
  getAnonymousUsage,
  profileApi,
  setChannels,
  setMembers,
  useGetCurrentUserProfileQuery,
} from "@meetin/components";
import { clientLogger } from "../logger";
import { useAppDispatch } from "store/hooks";
import { isExtensionLoaded } from "./utils";
import { styled, Stack } from "@meetin/uicore";
import Header from "./components/Header";
import "../supabase/realtime";
import { setUser as setUserInStore } from "./redux/appSlice";
import { useGetAnonymousTokenMutation } from "../anonymous/rtkAnonymous";
import useAnonymous from "./useAnonymous";

type Props = {
  supabaseClient: SupabaseClientType | null;
  user: Profile | null;
  hasExtension: boolean;
};
export const AppContext = createContext<Props>({
  supabaseClient: null,
  user: null,
  hasExtension: false,
});

const Main = styled("main")(({ theme }) => ({
  flex: 1,
  "& > div": {
    paddingLeft: theme.spacing(3.5),
  },
}));

const AppProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [client, setClient] = useState<SupabaseClientType | null>(null);
  const [user, setUser] = useState<Profile | null>(null);
  const dispatch = useAppDispatch();
  const [hasExtension, setHasExtension] = useState(isExtensionLoaded());
  const { data: profileData, isFetching } = useGetCurrentUserProfileQuery(
    undefined,
    { skip: !client && !user }
  );
  const [getAnonymousToken] = useGetAnonymousTokenMutation();
  const anonymousRequest = useRef<ReturnType<typeof getAnonymousToken> | null>(
    null
  );

  useAnonymous();

  // If user is not authenticated, create anonymous token
  const anonymousAuth = useCallback(async () => {
    anonymousRequest.current = getAnonymousToken({});
    const response = await anonymousRequest.current;
    if ("error" in response) {
      return;
    }

    const userMeta = {
      user_id: ANONYMOUS_USER_ID,
      created_at: new Date().toISOString(),
      image: null,
      name: "Anonymous",
      slack_team_id: "",
      slack_user_id: "",
    };
    dispatch(
      profileApi.util.updateQueryData(
        "getCurrentUserProfile",
        undefined,
        (currentProfile: Profile | null) => {
          if (!currentProfile) {
            return userMeta;
          }

          return { ...currentProfile, ...userMeta };
        }
      )
    );
    getAnonymousUsage();
  }, [dispatch, getAnonymousToken]);

  useEffect(() => {
    if (isFetching) {
      return;
    }
    if (profileData) {
      return;
    }

    anonymousAuth();
  }, [profileData, isFetching, anonymousAuth]);

  useEffect(() => {
    const timer = setInterval(() => {
      setHasExtension(isExtensionLoaded());
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const initSupabaseClient = (session: Session | null) => {
    const options: Parameters<
      typeof SupabaseClientHelper.initializeClient
    >["0"] = {
      supabaseUrl: `${process.env.REACT_APP_SUPABASE_URL}`,
      key: `${process.env.REACT_APP_SUPABASE_ANONYMOUS_KEY}`,
      access_token: null,
    };
    if (session) {
      // If user is logged in, remove anonymous token
      clientLogger.info("removing anonymous token");
      localStorage.removeItem(ANONYMOUS_TOKEN);
      anonymousRequest.current?.abort();

      options.access_token = session.access_token;
      options.options = {
        global: {
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        },
      };
    }
    SupabaseClientHelper.destroy();
    const newClient = SupabaseClientHelper.initializeClient(options);
    setClient(newClient);
  };

  const onAuthUpdate = useCallback(
    (e: CustomEvent<{ user: Profile; session: Session }>) => {
      const { user, session } = e.detail;

      clientLogger.info("onAuthUpdate", { user, session });
      setUser(user);
      dispatch(setUserInStore(user));
      dispatch(
        profileApi.util.updateQueryData(
          "getCurrentUserProfile",
          undefined,
          (currentProfile: Profile | null) => {
            if (!currentProfile) {
              return user;
            }

            return { ...currentProfile, ...user };
          }
        )
      );
      initSupabaseClient(session);
    },
    [dispatch]
  );

  const onSlackMembersUpdate = useCallback(
    (e: CustomEvent<{ slackMembers: SlackMember[] }>) => {
      dispatch(setMembers(e.detail.slackMembers || []));
    },
    [dispatch]
  );

  const onSlackChannelsUpdate = useCallback(
    (e: CustomEvent<{ slackChannels: SlackChannel[] }>) => {
      dispatch(setChannels(e.detail.slackChannels || []));
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener(
      AUTH_EVENT_NAME,
      onAuthUpdate as unknown as EventListener
    );
    window.addEventListener(
      SLACK_MEMBERS,
      onSlackMembersUpdate as unknown as EventListener
    );
    window.addEventListener(
      SLACK_CHANNELS,
      onSlackChannelsUpdate as unknown as EventListener
    );

    return () => {
      window.removeEventListener(
        AUTH_EVENT_NAME,
        onAuthUpdate as unknown as EventListener
      );
      window.removeEventListener(
        SLACK_MEMBERS,
        onSlackMembersUpdate as unknown as EventListener
      );
      window.removeEventListener(
        SLACK_CHANNELS,
        onSlackChannelsUpdate as unknown as EventListener
      );
    };
  }, [onAuthUpdate, onSlackChannelsUpdate, onSlackMembersUpdate]);

  // Initialize client with anonymouse key first
  useEffect(() => {
    initSupabaseClient(null);
  }, []);
  const values = useMemo(
    () => ({
      supabaseClient: client,
      user,
      hasExtension,
    }),
    [client, user, hasExtension]
  );

  return (
    <AppContext.Provider value={values}>
      <ComponentsProvider
        user={user}
        webAppUrl={process.env.REACT_APP_WEBAPP_URL || ""}
      >
        <Stack sx={{ minHeight: "100vh" }}>
          <Header hasExtension={hasExtension} />
          <Main id={WEB_MAIN_ELEMENT_ID}>{children}</Main>
        </Stack>
      </ComponentsProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
