export enum AnalyticsEvents {
  ADD_TO_SLACK_CTA_CLICKED = "Add to Slack CTA Button Clicked",
  ASK_LAYER_CTA_CLICKED = "Ask Layer CTA Button Clicked",
  COLLECTION_ADDED_COMMENT = "Comment Added to Collection",
  COLLECTION_CREATED = "Collection Created",
  COLLECTION_REMOVED_COMMENT = "Comment Removed from Collection",
  COMMENTS_IN_PAGE_COLLAPSE_CLICKED = "Comments-in-page Collapse Clicked",
  COMMENTS_IN_PAGE_EXPAND_CLICKED = "Comments-in-page Expand Clicked",
  COMMENT_ADDED = "Comment Added",
  COMMENT_ANCHOR_TOGGLED = "Comment Anchor Toggled",
  COMMENT_CANCELLED = "Comment Cancelled",
  COMMENT_REPLIES_HIDE_CLICKED = "Hide Comment Replies Clicked",
  COMMENT_REPLIES_SHOW_CLICKED = "Show Comment Replies Clicked",
  COMMENT_REPLY_ADDED = "Comment Reply Added",
  COMMENT_RESOLVED = "Comment Resolved",
  DISCONNECT_SLACK_CTA_CLICKED = "Disconnect Slack CTA Button Clicked",
  MENU_CLOSE_BUTTON_CLICKED = "Menu - Close Button Clicked",
  MENU_COLLAPSE_BUTTON_CLICKED = "Menu - Collapse Button Clicked",
  MENU_COLLECTIONS_BUTTON_CLICKED = "Menu - Collections Button Clicked",
  MENU_EXPAND_BUTTON_CLICKED = "Menu - Expand Button Clicked",
  MENU_OPENED = "Menu Opened",
  MENU_SETTINGS_BUTTON_CLICKED = "Menu - Settings Button Clicked",
  MENU_SHARE_BUTTON_CLICKED = "Menu - Share Tab Button Clicked",
  MENU_TITLE_BACK_BUTTON_CLICKED = "Menu - Title Back Button Clicked",
  NEW_INSIGHT_GENERATED = "New Insight Generated",
  NEW_POST_CAPTURED = "New Post Captured",
  NEW_QUESTION_ASKED = "New Question Asked",
  NOTEBOOK_TAB_CLICKED = "Notebook Tab Clicked",
  RESEARCH_PROJECT_CLEARED = "Research Project Cleared",
  RESEARCH_PROJECT_SET = "Research Project Set",
  SHARE_INVITE_JOINED = "Share Session - Invite Joined",
  SHARE_INVITE_SENT = "Share Tab Invite Sent",
  SHARE_SESSION_DISCONNECT_CLICKED = "Share Session - Disconnect Button Clicked",
  SHARE_SESSION_FOLLOW_CLICKED = "Share Session - Follow User Clicked",
  SHARE_SESSION_UNFOLLOW_CLICKED = "Share Session - Unfollow User Clicked",
  SIDE_PANEL_OPENED = "Side Panel Opened",
  SIGNED_IN = "User Signed In",
  SIGNED_OUT = "User Signed Out",
  SOURCE_CLICKED = "Source Clicked",
  TAGGED_CHANNEL_COMMENT_ADDED = "Channel-Tagged Comment Added",
  TAGGED_CHANNEL_COMMENT_REPLY_ADDED = "Channel-Tagged Comment Reply Added",
  TAGGED_USER_COMMENT_ADDED = "User-Tagged Comment Added",
  TAGGED_USER_COMMENT_REPLY_ADDED = "User-Tagged Comment Reply Added",
  USER_TOUCHED_NOTEBOOK = "User Touched Notebook",
}
