import { Database } from "@meetin/supabase";
import { Collection } from "./collection";

export type Post = Database["public"]["Tables"]["posts"]["Row"];
export type PostWithCollections = {
  post_id_collection_id?: [{ collections: Collection }] | null;
} & Post;
export enum PostTypes {
  COMMENT = "Comment",
  POST = "Post",
}

// Post can be shared by tagging users/channels via slack or as public link
export enum PostChannelTypes {
  "Link" = "Link",
  "Slack" = "Slack",
}
export enum PostStatus {
  ACTIVE = "Active",
  ARCHIVED = "Archived",
}
export type PostAnchor = Pick<
  Post,
  | "anchor_text"
  | "xpath"
  | "text_offset"
  | "left_percentage"
  | "top_percentage"
  | "highlight"
  | "highlight_text"
  | "rectangle_height"
  | "rectangle_width"
  | "page_url"
>;

export type PageOGDetails = Database["public"]["Tables"]["og_meta"]["Row"];
