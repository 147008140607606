import { Button, CircularProgress, Typography } from "@meetin/uicore";
import { GenerateIcon } from "@meetin/uicore/icons";

interface Props {
  checkRelevancy: () => Promise<void>;
  isLoading: boolean;
}

export const GenerateInsightsButton = ({
  checkRelevancy,
  isLoading,
}: Props) => (
  <Button
    onClick={() => checkRelevancy()}
    variant="text"
    color="secondary"
    disabled={isLoading}
  >
    {isLoading ? (
      <CircularProgress color="primary" size={19} sx={{ marginRight: "4px" }} />
    ) : (
      <GenerateIcon
        style={{
          marginRight: "4px",
          height: "19px",
          width: "19px",
        }}
      />
    )}
    <Typography variant="body2">Generate Insights</Typography>
  </Button>
);
