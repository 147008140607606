export enum Tabs {
  PAGE_INSIGHTS = 0,
  NOTEBOOK = 1,
}

export interface UserPreferences {
  postsState?: boolean;
  activeCollectionId?: string;
  searchAllPages?: boolean;
  activeTab?: Tabs;
}

export type UserPreference = keyof UserPreferences;
