import { REALTIME_POSTGRES_CHANGES_LISTEN_EVENT } from "@supabase/supabase-js";
import { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useSupabaseSubscriber } from "../supabase";
import { ComponentsContext } from "../common";
import { clientLogger } from "../logger";
import { FeatureUsage } from "../subscription/types";
import {
  rtkSubscriptionApi,
  useGetFeatureUsageQuery,
  useGetServerDateQuery,
} from "../subscription";

const useFeaturesUsageSubscription = () => {
  const { subscribe, unsubscribe } = useSupabaseSubscriber();
  const { user } = useContext(ComponentsContext);
  const { data: currentDate } = useGetServerDateQuery(undefined, {
    skip: !user,
  });

  const { refetch } = useGetFeatureUsageQuery(
    { date: currentDate || "" },
    {
      skip: !user?.user_id || !currentDate,
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.user_id || !currentDate) {
      return undefined;
    }

    const filter = `user_id=eq.${user.user_id}`;
    subscribe({
      channel: "features_usage",
      filter: {
        event: REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.ALL,
        schema: "public",
        table: "features_usage",
        filter,
      },
      callback: (payload) => {
        clientLogger.info("features usage update received!", { payload });
        const featuresUsage = payload.new as FeatureUsage;

        // If new date is added, refetch latest feature usage
        if (
          payload.eventType === REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.INSERT
        ) {
          refetch();
          return;
        }

        dispatch(
          // @ts-expect-error valid type
          rtkSubscriptionApi.util.updateQueryData(
            "getFeatureUsage",
            { date: currentDate },
            (_current: FeatureUsage | null) => featuresUsage
          )
        );
      },
    });

    return () => {
      unsubscribe("features_usage");
    };
  }, [subscribe, unsubscribe, user?.user_id, refetch, dispatch, currentDate]);

  return {};
};

export default useFeaturesUsageSubscription;
