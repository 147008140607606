import { useRef, useEffect } from "react";
import {
  Box,
  List,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@meetin/uicore";
import { RelatedIcon, UnrelatedIcon } from "@meetin/uicore/icons";
import { theme } from "@meetin/uicore/src/theme/theme";
import AskLayerResponseComponent from "./AskLayerResponse";
import WebsiteSearcher from "../WebsiteSearcher";
import WebsiteSearcherButton from "../WebsiteSearcherButton";
import {
  AiMessage as AiMessageType,
  AskLayerQueryType,
  SearchOperationStatus,
} from "../types";
import KeyPointRow from "../KeyPointRow";
import { PageDetails } from "../../../posts";
import ProcessOtherChunks from "./ProcessOtherChunks";

interface Props {
  chat: AiMessageType;
}

const AnswerSourcesList = styled(List)<{ type: AskLayerQueryType }>(
  (props) => ({
    display: "flex",
    flexDirection:
      props.type === AskLayerQueryType.AI_SEARCH ? "row" : "column",
    gap: "4px",
    padding: "0 18px",
    margin: "8px 0 12px",
    fontFamily: theme.typography.fontFamily,

    // move these styles into the list item component itself
    "& .MuiListItem-root": {
      color: "rgba(0, 5, 15, 0.85)",
      borderRadius: props.type === AskLayerQueryType.AI_SEARCH ? "50%" : 0,
      width: "auto",
      height: "auto",
      textAlign:
        props.type === AskLayerQueryType.AI_SEARCH
          ? "center !important"
          : "left",
    },
  })
);

export const AiMessage = ({ chat }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const isFailedAnswer = (chat: AiMessageType) =>
    chat.answer?.[0]?.message?.content &&
    (!chat.sources?.length ||
      chat.answer[0].message.content.answer.startsWith(
        "Sorry, I could not find an answer"
      ));

  const onTitleClick = (url: string) => {
    chrome.runtime.sendMessage({
      type: "NAVIGATE_TO_URL",
      message: url,
    });
  };
  const getSearchContent = (chat: AiMessageType) => {
    if (chat.status === SearchOperationStatus.STREAMING) return null;

    if (chat.searchAllPages) return <WebsiteSearcher chat={chat} />;

    if (chat.type === AskLayerQueryType.AI_SEARCH && isFailedAnswer(chat))
      return <WebsiteSearcherButton chat={chat} />;

    return null;
  };

  const getRelvantContent = (chat: AiMessageType) => {
    if (
      chat.type !== AskLayerQueryType.AI_INSIGHTS ||
      !chat.collectionId ||
      !chat.answer[0].message.content.answer
    ) {
      return null;
    }

    const isRelavant = chat.answer[0].message.content.relevant;
    return (
      <Tooltip title="Relevancy is determined based on research project and page content.">
        <Typography
          color={isRelavant ? "rgba(52, 199, 89, 1)" : "rgba(255, 149, 0, 1)"}
          variant="h6"
          sx={{
            pl: "18px",
            mt: "8px",
            mb: "4px",
            lineHeight: "1.2",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isRelavant ? (
            <RelatedIcon
              style={{
                width: "16px",
                height: "16px",
                marginRight: 4,
                verticalAlign: "middle",
              }}
            />
          ) : (
            <UnrelatedIcon
              style={{
                width: "16px",
                height: "16px",
                marginRight: 4,
                verticalAlign: "middle",
              }}
            />
          )}
          {isRelavant ? "Related to Research" : "Unrelated to Research"}
        </Typography>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [chat.status]);

  return (
    <div
      ref={ref}
      style={{
        scrollPadding: "200px",
      }}
    >
      <Stack
        textAlign="left"
        key={chat.requestId}
        sx={{
          "& > .MuiStack-root": {
            mb: 0,
            borderRadius: 0,
            border: "none",
            background: "#F1F1F1",
          },
        }}
      >
        {chat.type !== AskLayerQueryType.AI_INSIGHTS_ALL ? (
          <PageDetails pageUrl={chat.url} hideDetails onClick={onTitleClick} />
        ) : null}

        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#F1F1F1",
            color: "#2253E7",
            padding: "4px 18px 8px",
            lineHeight: "1.4",
          }}
        >
          {chat.question}
        </Typography>
        {chat.status === SearchOperationStatus.RUNNING ? (
          <Box
            mt={1}
            pl={2.25}
            sx={{
              "& .MuiSkeleton-root": {
                mb: 0.5,
              },
            }}
          >
            <Skeleton width="98%" variant="rounded" height={16} />
            <Skeleton width="90%" variant="rounded" height={16} />
            <Skeleton width="95%" variant="rounded" height={16} />
            <Skeleton width="40%" variant="rounded" height={16} />
          </Box>
        ) : (
          <>
            {getRelvantContent(chat)}
            {/* for insights, this component will do further summarization for other chunks */}
            {chat.type === AskLayerQueryType.AI_INSIGHTS ? (
              <ProcessOtherChunks chat={chat} portal={ref.current} />
            ) : null}
            {chat.type === AskLayerQueryType.AI_INSIGHTS ? (
              <Typography
                color="primary"
                variant="h6"
                sx={{
                  mt: "8px",
                  pl: "18px",
                  color: "rgba(153, 155, 159, 1)",
                  lineHeight: "1.2",
                }}
              >
                Conceptual Overview
              </Typography>
            ) : null}
            <AskLayerResponseComponent data={chat.answer} />
            {chat.sources?.length ? (
              <>
                {chat.type === AskLayerQueryType.AI_INSIGHTS ? (
                  <Typography
                    color="primary"
                    variant="h6"
                    sx={{
                      pl: "18px",
                      color: "rgba(153, 155, 159, 1)",
                      lineHeight: "1.2",
                    }}
                  >
                    Key Points
                  </Typography>
                ) : null}

                <AnswerSourcesList type={chat.type}>
                  {chat.sources.map((text, index) => (
                    <KeyPointRow
                      text={text}
                      key={text}
                      index={index}
                      showIndex={chat.type === AskLayerQueryType.AI_SEARCH}
                      disableIndex={
                        chat.status === SearchOperationStatus.STREAMING
                      }
                      disableDefaultHighlight={
                        chat.type === AskLayerQueryType.AI_SEARCH ||
                        chat.status === SearchOperationStatus.STREAMING
                      }
                    />
                  ))}
                </AnswerSourcesList>
              </>
            ) : null}
            {/* Search other pages only if sources are not found and search all pages switch is enabled */}
            {getSearchContent(chat)}
          </>
        )}
      </Stack>
    </div>
  );
};
