import { Stack } from "@meetin/uicore";
import { useSelector } from "react-redux";
import { selectAiMessages } from "../redux/askLayerSelectors";
import { AiMessage } from "./AiMessage";

const AiMessages = () => {
  const chats = useSelector(selectAiMessages);

  return (
    <Stack flex={1} paddingBottom="20px">
      {chats.length > 0
        ? chats.map((chat) => <AiMessage key={chat.requestId} chat={chat} />)
        : null}
    </Stack>
  );
};

export default AiMessages;
