import { PageOGDetails } from "@meetin/shared";
import { useDispatch } from "react-redux";
import { useEffect, useContext } from "react";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { Link, Stack, Typography, styled } from "@meetin/uicore";
import FavIcon from "./FavIcon";
import { ogDetailsApi, useGetOGDetailsQuery } from "../hooks/rtkOGQueries";
import { getOGDataFromTab } from "../helpers";
import { clientLogger } from "../../logger";
import { ComponentsContext } from "../../common";
import { getUrlWithoutHash } from "../utils";

type Props = {
  pageUrl?: string | null;
  hideDetails?: boolean;
  onClick?: (url: string) => void;
};

const Details = styled("div")(({ theme }) => ({
  display: "block",
  width: "100%",
  [theme.breakpoints.up("sm").replace("@media", "@container")]: {
    display: "none",
  },
  [theme.breakpoints.up("xs").replace("@media", "@container")]: {
    display: "none",
  },
  [theme.breakpoints.up("md").replace("@media", "@container")]: {
    display: "block",
  },
}));

const PageDetails = ({
  pageUrl,
  hideDetails,
  onClick,
}: Props): JSX.Element | null => {
  const { currentPageUrl } = useContext(ComponentsContext);
  const { data, isFetching } = useGetOGDetailsQuery(
    { url: pageUrl || "" },
    { skip: !pageUrl }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFetching || data) {
      return;
    }

    if (getUrlWithoutHash(currentPageUrl) !== pageUrl) {
      return;
    }

    getOGDataFromTab()
      .then((details) => {
        dispatch(
          // @ts-expect-error valid type
          ogDetailsApi.util.updateQueryData(
            "getOGDetails",
            {
              url: pageUrl,
            },
            (_currentData: PageOGDetails | null) => details as PageOGDetails
          )
        );
        return data;
      })
      .catch((err) => {
        clientLogger.error("Error getting OG data from tab", {}, err);
      });
  }, [data, isFetching, currentPageUrl, pageUrl, dispatch]);

  if (!pageUrl || !data) {
    return null;
  }

  const handleClick = () => {
    onClick?.(pageUrl);
  };
  return (
    <Stack
      justifyContent="flex-start"
      direction="row"
      sx={{
        borderRadius: 2,
        border: "1px solid rgba(0, 0, 0, 0.15)",
        p: 0.875,
        flex: 1,
        maxWidth: "100%",
        mb: 1.5,
        cursor: "pointer",
        padding: "8px 18px 4px",
      }}
    >
      {data.favIcon ? <FavIcon url={data.favIcon} /> : null}
      <Stack
        onClick={handleClick}
        direction="column"
        flex={1}
        sx={{
          gridTemplateColumns:
            "repeat(auto-fill, minmax(min(10rem, 100%), 1fr))",
          containerType: "inline-size",
          maxWidth: "calc(100% - 30px)",
        }}
      >
        <Typography
          variant="body1"
          noWrap
          sx={{
            color: "rgba(0, 5, 15, 0.6)",
            lineHeight: "1.2",

            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {data.title}
        </Typography>

        {!hideDetails ? (
          <Details>
            <Typography
              variant="body2"
              sx={{ color: "rgba(0, 5, 15, 0.5)", py: 0.5 }}
            >
              {data.description}
            </Typography>
            <Link
              href={pageUrl}
              color="rgba(0, 5, 15, 0.85)"
              width="max-content"
              target="_blank"
              rel="noreferrer"
            >
              <BsArrowUpRightSquare style={{ verticalAlign: "text-bottom" }} />{" "}
              {pageUrl}
            </Link>
          </Details>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default PageDetails;
