import { Typography, Button, Box, styled } from "@meetin/uicore";
import { GenerateIcon } from "@meetin/uicore/icons";
import { blue } from "@meetin/uicore/src/theme/colors";

interface Props {
  checkRelevancy: () => Promise<void>;
}

const StyledGenerateIcon = styled(GenerateIcon)(() => ({
  marginRight: "8px",
  width: "23px",
  height: "23px",
  fill: blue[7],
}));

export const InsightsBlankState = ({ checkRelevancy }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    padding="16px"
  >
    <Typography variant="h6">Get insights from this page</Typography>
    <Typography
      variant="body1"
      sx={{
        color: "#999B9F",
        margin: "16px 0",
      }}
    >
      Instantly identify the relevant informations for your research on this
      page.
    </Typography>
    <Button
      onClick={() => checkRelevancy()}
      variant="outlined"
      sx={{
        border: `1px solid ${blue[7]}`,
        padding: "8px 16px",
        alignItems: "center",
      }}
    >
      <StyledGenerateIcon />
      <Typography variant="h6">Generate</Typography>
    </Button>
  </Box>
);
