import { useRef } from "react";
import { Box, Stack } from "@meetin/uicore";
import { useSelector } from "react-redux";
import AiMessages from "./AiMessages";
import AskLayerForm from "./AskLayerForm";
import { selectAiMessages } from "../redux/askLayerSelectors";
import { GenerateInsightsButton } from "./GenerateInsightsButton";
import WebsiteSearcherToggle from "./WebsiteSearcherToggle";
import { useCheckPageRelevancy } from "../useCheckRelevancy";
import { InsightsBlankState } from "../../../pageInsights/components";
import UsedQuestions from "./UsedQuestions";
import QuestionsLimit, { QuestionsLimitRef } from "./QuestionsLimit";

type Props = {
  disableWebBrowsing?: boolean;
};
const AIComponent = ({ disableWebBrowsing }: Props) => {
  // const ref = useRef<HTMLDivElement>(null);
  const chats = useSelector(selectAiMessages);
  const { checkRelevancy, isLoading } = useCheckPageRelevancy();
  const questionLimitRef = useRef<QuestionsLimitRef>(null);

  const hasAnyChats = chats.length > 0;

  // useLayoutEffect(() => {
  //   if (!ref.current) {
  //     return;
  //   }

  //   console.log();
  //   ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
  // }, [chats]);

  const onNewQuestionSubmit = () => {
    questionLimitRef.current?.onNewQuestionSubmit();
  };

  const handleCheckRelavancy = async () => {
    questionLimitRef.current?.onNewQuestionSubmit();

    checkRelevancy();
  };

  return (
    <Stack
      flex={1}
      sx={{
        position: "relative",
        pb: 0,
        paddingTop: "16px",
        overflowY: "auto",
        flexBasis: "auto",
      }}
    >
      {hasAnyChats ? (
        <AiMessages />
      ) : (
        <InsightsBlankState checkRelevancy={handleCheckRelavancy} />
      )}
      {/* floating bottom component - might want to move to a separate containing component */}
      <Stack
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          margin: "4px",
          filter: "drop-shadow(0px 1px 40px white)",
        }}
        border="1px solid #E5E5E5"
        borderTop="0"
        borderRadius="20px"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.10)"
        bgcolor="white"
      >
        <AskLayerForm
          isGenerateInsightsVisible={hasAnyChats}
          onNewQuestionSubmit={onNewQuestionSubmit}
        />
        <QuestionsLimit ref={questionLimitRef} />

        <Box
          position="relative"
          sx={{
            borderTop: "1px solid #E5E5E5",
            whiteSpace: "nowrap",
            minHeight: 40,
            pr: 2,
          }}
        >
          {hasAnyChats && (
            <GenerateInsightsButton
              checkRelevancy={handleCheckRelavancy}
              isLoading={isLoading}
            />
          )}
          {!disableWebBrowsing ? <WebsiteSearcherToggle /> : null}
          <UsedQuestions />
        </Box>
      </Stack>
    </Stack>
  );
};

export default AIComponent;
