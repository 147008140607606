import {
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  showErrorNotification,
  showInfoNotification,
  styled,
} from "@meetin/uicore";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useContext, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { blue } from "@meetin/uicore/src/theme/colors";
import { ArrowRightIcon } from "@meetin/uicore/icons";
import { useLazyGetPageMarkdownQuery } from "../rtkAskLayerQueries";
// import MarkdownDisplay from "./MarkdownDisplay";
// import PromptSuggestions from "./PromptSuggestions";
import { setMessages } from "../redux/askLayerSlice";
import {
  selectSearchAllPages,
  selectActiveCollectionId,
  selectIsPdfProcessing,
} from "../redux/askLayerSelectors";
import useEmbedding from "../useEmbedding";
import { AskLayerQueryType, SearchOperationStatus } from "../types";
import useAnonymousChatUsage from "./useAnonymousChatUsage";
import { trackEvent, AnalyticsEvents } from "../../../analytics";
import { ComponentsContext } from "../../../common";
import { clientLogger } from "../../../logger";
import { getSupabaseErrorMessage } from "../../../supabase";
import useSubscriptionLimits from "../../../subscription/useSubscriptionLimits";
import useAICompletion from "./useAICompletion";

const schema = Yup.object({
  query: Yup.string().required("Query is required"),
  promptText: Yup.string().optional(),
  requestId: Yup.string().optional(),
}).required();

const StyledArrowRightIcon = styled(ArrowRightIcon)(() => ({
  fill: blue[7],
}));

type AskLayerFormInput = {
  query: string;
  promptText: string | undefined;
  requestId: string | undefined;
};

interface Props {
  isGenerateInsightsVisible: boolean;
  onNewQuestionSubmit: () => void;
}

const AskLayerForm = ({
  isGenerateInsightsVisible,
  onNewQuestionSubmit,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  const searchAllPages = useSelector(selectSearchAllPages);
  const activeCollectionId = useSelector(selectActiveCollectionId);
  const isPdfProcessing = useSelector(selectIsPdfProcessing);
  const { currentPageUrl } = useContext(ComponentsContext);
  const [getPageMarkdown, { isLoading: loadingPageMarkdown }] =
    useLazyGetPageMarkdownQuery();
  const isPreprocessing = useRef(false);
  const { isQuestionsLimitReached } = useSubscriptionLimits();
  const { getAnonymousUsageText, isAnonymousLimtExhausted } =
    useAnonymousChatUsage();
  const time = useRef(0);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitted },
  } = useForm<AskLayerFormInput>({
    resolver: yupResolver(schema),
  });

  const { upsertEmbedding, isLoading: upsertingEmbedding } = useEmbedding();

  const {
    isFetching: askingLayer,
    onSubmit,
    answer,
    isStreaming,
  } = useAICompletion();

  useEffect(() => {
    const requestId = getValues("requestId");
    if (!requestId || isStreaming) {
      return;
    }

    dispatch(
      setMessages({
        requestId,
        status: SearchOperationStatus.COMPLETED,
      })
    );
  }, [dispatch, getValues, isStreaming]);

  useEffect(() => {
    if (!answer) {
      return;
    }
    dispatch(
      setMessages({
        answer: [
          {
            index: 0,
            message: {
              role: "assistant",
              content: answer,
            },
            finish_reason: "stop",
          },
        ],
        requestId: getValues("requestId") || `${Date.now()}`,
        sources: answer.sources,
        status: SearchOperationStatus.STREAMING,
      })
    );
  }, [answer, currentPageUrl, dispatch, getValues, searchAllPages]);

  const isLoading =
    isSubmitted &&
    (loadingPageMarkdown ||
      upsertingEmbedding ||
      askingLayer ||
      isPdfProcessing);

  // read the page/pdf content and create embeddings to reduce time for first question
  const preprocessPageContent = async () => {
    // In web, if pdf processing is happening, dont preprocess the content
    if (isPdfProcessing) {
      clientLogger.debug("pdf preprocessing in progress");
      return;
    }
    if (isAnonymousLimtExhausted || isQuestionsLimitReached()) {
      clientLogger.debug("Limit exhausted");
      return;
    }
    if (isPreprocessing.current) {
      clientLogger.debug("preprocessing in progress");
      return;
    }
    isPreprocessing.current = true;
    const response = await getPageMarkdown(currentPageUrl);
    if (response.data) {
      await upsertEmbedding({ markdown: response.data, url: currentPageUrl });
    }
    isPreprocessing.current = false;
  };

  const onFormSubmit = async (data: AskLayerFormInput) => {
    setIsProcessing(true);

    if (isPdfProcessing) {
      showInfoNotification({
        message:
          "We are processing your PDF. Please try again in a few seconds.",
      });
      setIsProcessing(false);
      return;
    }
    if (isAnonymousLimtExhausted || isQuestionsLimitReached()) {
      onNewQuestionSubmit();

      clientLogger.debug("Limit exhausted");
      if (isAnonymousLimtExhausted) {
        showInfoNotification({
          message:
            "You have reached the limit of guest usage. Please signup to continue.",
        });
      }
      setIsProcessing(false);
      return;
    }

    if (isPreprocessing.current) {
      clientLogger.debug("preprocessing in progress");
      setTimeout(() => {
        onFormSubmit(data);
      }, 500);
      setIsProcessing(false);
      return;
    }
    time.current = Date.now();
    clientLogger.info("ask layer form submitted", data);
    const { data: markdown, error } = await getPageMarkdown(currentPageUrl);

    if (!markdown || error) {
      clientLogger.error("not able to get tab main html");
      showErrorNotification({
        message: getSupabaseErrorMessage(error) || "Please try again later.",
      });

      trackEvent(AnalyticsEvents.NEW_QUESTION_ASKED, {
        withinProject: `${Boolean(activeCollectionId)}`,
        answerFound: "false",
        callFailed: "true",
      });
      setIsProcessing(false);
      return;
    }

    const requestId = `${Date.now()}`;
    setValue("requestId", requestId);
    dispatch(
      setMessages({
        question: getValues("query"),
        answer: [],
        requestId,
        type: AskLayerQueryType.AI_SEARCH,
        sources: [],
        searchAllPages: false, // do not enable search when asking a new question
        url: currentPageUrl,
        searchQuery: getValues("query"),
        status: SearchOperationStatus.RUNNING,
      })
    );
    const response = await upsertEmbedding({ markdown, url: currentPageUrl });
    if ("error" in response) {
      const message = getSupabaseErrorMessage(response.error);
      clientLogger.error("error upserting embedding", { error: message });
      showErrorNotification({
        message,
      });

      trackEvent(AnalyticsEvents.NEW_QUESTION_ASKED, {
        withinProject: `${Boolean(activeCollectionId)}`,
        answerFound: "false",
        callFailed: "true",
      });

      setIsProcessing(false);
      return;
    }
    await onSubmit({
      ...data,
      markdown,
      url: currentPageUrl,
      type: AskLayerQueryType.AI_SEARCH,
    });
    setIsProcessing(false);
  };

  // const onPromptSelect = (promptText: string) => {
  //   setValue("query", promptText);
  // };

  /* TODO: move this Stack somewhere higher where it can share with the
      generate insights button initially hidden */
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack sx={{ p: 1 }}>
        {/* <PromptSuggestions onPromptSelect={onPromptSelect} /> */}
        <OutlinedInput
          onFocus={preprocessPageContent}
          size="small"
          fullWidth
          {...register("query")}
          //  handle a case where a question was already asked before and change placeholder
          placeholder={
            !isGenerateInsightsVisible
              ? "Ask a question about this page"
              : "Ask a followup question"
          }
          sx={{
            pr: 0,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiIconButton-edgeEnd": {
              marginRight: "0px",
            },
            "& input": {
              px: "10px",
            },
          }}
          disabled={isProcessing}
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="submit" edge="end" disabled={isLoading}>
                {!isLoading ? (
                  <StyledArrowRightIcon />
                ) : (
                  <CircularProgress size={18} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        {getAnonymousUsageText()}
        {/* <TextField
            label="Prompt Text"
            variant="outlined"
            size="small"
            fullWidth
            {...register("promptText")}
          /> */}
        {/* <LoadingButton type="submit" loading={isLoading}>
            Ask Layer
          </LoadingButton> */}
      </Stack>
      {/* {pageMarkdown ? <MarkdownDisplay markdown={pageMarkdown} /> : null} */}
    </form>
  );
};

export default AskLayerForm;
