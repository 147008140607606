import { StarsIcon } from "@meetin/uicore/icons";
import { Typography } from "@meetin/uicore";
import useSubscriptionLimits from "../../../subscription/useSubscriptionLimits";
import { Tooltip } from "../../../common";

const UsedQuestions = () => {
  const { allowedQuestionsLimit, usedQuestionsToday } = useSubscriptionLimits();

  if (allowedQuestionsLimit === -1) {
    return null;
  }

  return (
    <Tooltip
      title={`${
        allowedQuestionsLimit - usedQuestionsToday
      } questions left. Reloads everyday`}
    >
      <Typography variant="body1" sx={{ float: "right", lineHeight: 2.5 }}>
        <StarsIcon /> {allowedQuestionsLimit - usedQuestionsToday}/
        {allowedQuestionsLimit} left
      </Typography>
    </Tooltip>
  );
};

export default UsedQuestions;
