import { List, ListItem, Typography } from "@meetin/uicore";
import { theme } from "@meetin/uicore/src/theme/theme";
import { AskLayerResponse } from "../types";

type Props = {
  data?: AskLayerResponse | null;
};
const AskLayerResponseComponent = ({ data }: Props) => {
  if (!data) {
    return null;
  }

  return (
    <List
      sx={{
        fontSize: "16px",
        lineHeight: 1.4,
        fontWeight: 400,
        padding: "0px 18px",
        margin: "8px 0 12px",
        fontFamily: theme.typography.fontFamily,
      }}
    >
      {data.map((choice) => (
        <ListItem
          key={choice.index}
          sx={{ flexDirection: "column", alignItems: "baseline" }}
        >
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "pre-wrap",
              fontSize: "16px",
              lineHeight: 1.4,
              fontWeight: 400,
            }}
          >
            {choice.message.content.answer}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default AskLayerResponseComponent;
