import { useContext } from "react";
import { useSelector } from "react-redux";
import { selectAllowedQuestionsPerDay } from "./subscriptionSelectors";
import {
  useGetFeatureUsageQuery,
  useGetServerDateQuery,
} from "./rtkSubscription";
import { ComponentsContext } from "../common";

const useSubscriptionLimits = () => {
  const allowedQuestionsLimit = useSelector(selectAllowedQuestionsPerDay);
  const { user } = useContext(ComponentsContext);
  const { data: currentDate } = useGetServerDateQuery(undefined, {
    skip: !user,
  });

  const { data } = useGetFeatureUsageQuery(
    { date: currentDate || "" },
    {
      skip: !user?.user_id || !currentDate,
    }
  );

  const usedQuestionsToday = data?.questions_per_day || 0;

  const isQuestionsLimitReached = () => {
    if (allowedQuestionsLimit === -1) {
      return false;
    }
    return allowedQuestionsLimit <= usedQuestionsToday;
  };

  return { isQuestionsLimitReached, allowedQuestionsLimit, usedQuestionsToday };
};

export default useSubscriptionLimits;
