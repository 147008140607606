import { MarkAsDoneIcon } from "@meetin/uicore/icons";
import { useDispatch } from "react-redux";
import {
  CircularProgress,
  Close,
  IconButton,
  showErrorNotification,
  showSuccessNotification,
} from "@meetin/uicore";
import { useState, MouseEvent } from "react";
import { Post, rtkApi } from "@meetin/shared";
import {
  Actions,
  AnalyticsEvents,
  SupabaseClientHelper,
  Tooltip,
  clientLogger,
  trackActionEnd,
  trackActionStart,
  trackEvent,
} from "../..";

type Props = {
  postId: Post["id"];
  onSuccess: () => void;
  blockMode?: boolean;
};

// needs renaming
const MarkAsDoneButton = ({
  postId,
  onSuccess,
  blockMode = false,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
    if (blockMode) {
      event.stopPropagation();
    }

    try {
      const supabaseClient = SupabaseClientHelper.getSupabaseClient();

      setIsLoading(true);
      trackActionStart(Actions.COMMENT_RESOLVE_BUTTON_CLICKED);
      const { data, error } = await supabaseClient.functions.invoke<{
        post: Post;
      }>("mark-as-done", {
        body: { postId },
      });

      if (error) {
        throw new Error(error.message);
      }
      if (!data) {
        throw new Error("Invalid response");
      }

      dispatch(
        rtkApi.util.invalidateTags([
          "getPostsInPageByUserId",
          "getPostsInPageByChannels",
          "getPostsInPageByCollections",
          "getPostsInCollection",
          "getUnsortedPosts",
        ])
      );

      trackActionEnd(
        Actions.COMMENT_RESOLVE_BUTTON_CLICKED,
        Actions.COMMENT_RESOLVE_SUCCESS,
        { commentId: postId }
      );
      trackEvent(AnalyticsEvents.COMMENT_RESOLVED);

      onSuccess();
      showSuccessNotification({ message: "Successfully marked as done!" });
    } catch (err) {
      clientLogger.error("archiving error", { postId }, err as Error);
      trackActionEnd(
        Actions.COMMENT_RESOLVE_BUTTON_CLICKED,
        Actions.COMMENT_RESOLVE_ERROR,
        { commentId: postId }
      );
      showErrorNotification({ message: (err as Error).message });
    }
    setIsLoading(false);
  };
  return (
    <Tooltip title="Delete" placement="top">
      <IconButton
        sx={
          blockMode
            ? {
                width: "15px",
                height: "15px",
                padding: "2px",
                marginLeft: "auto",
              }
            : {
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }
        }
        onClick={onButtonClick}
      >
        {/* eslint-disable no-nested-ternary */}
        {isLoading ? (
          <CircularProgress size={blockMode ? 15 : 18} />
        ) : blockMode ? (
          <Close />
        ) : (
          <MarkAsDoneIcon />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default MarkAsDoneButton;
