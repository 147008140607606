import * as pdfjs from "pdfjs-dist";
import { rtkApi } from "@meetin/shared";
import { showInfoNotification } from "@meetin/uicore";
import { SupabaseClientHelper } from "../supabase";
import { clientLogger } from "../logger";
import { selectCurrentUser } from "../user/hooks/rtkProfile";
import { fetchFromApi } from "../api";

type PdfContentQueryProps = {
  data: Parameters<typeof pdfjs.getDocument>[0];
};

const getLoadingTask = (data: Parameters<typeof pdfjs.getDocument>[0]) => {
  if (typeof data === "string") {
    // For files uploaded in web
    if (data.startsWith("data:")) {
      return pdfjs.getDocument({ data: atob(data.split(",")[1]) });
    }
  }

  return pdfjs.getDocument(data);
};

export const rtkPdfApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getPdfContent: builder.query<string | null, PdfContentQueryProps>({
      queryFn: async ({ data }: PdfContentQueryProps, api) => {
        clientLogger.info("getPdfContent", { data: api.getState() });
        // @ts-expect-error TODO: set correct type for getstate
        const userId = selectCurrentUser(api.getState())?.data?.user_id;

        if (!userId) {
          throw new Error("Missing user id");
        }
        const loadingTask = getLoadingTask(data);
        const pdf = await loadingTask.promise;
        const pdfContent = await pdf.getData();

        const size = pdfContent.length / 1024 / 1024;
        // Allow upto 5 MB pdf files
        if (size > 5) {
          showInfoNotification({
            message: `File size is more than ${Math.round(
              size
            )}MB. Please wait until we process the file.`,
          });
        }
        const fileBuffer = await pdf.saveDocument();
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        const fileName = new URL(data as string).pathname.split("/").pop();

        const { data: uploadData, error } = await supabaseClient.storage
          .from("ai-files")
          .upload(`${userId}/${fileName}`, fileBuffer, {
            contentType: "application/pdf",
            upsert: true,
          });

        if (error) {
          throw new Error(error.message);
        }

        const { markdown } = await fetchFromApi("pdf2md", {
          data: uploadData.path,
        });

        return { data: markdown };
      },
    }),
  }),
});

export const { useLazyGetPdfContentQuery } = rtkPdfApi;
