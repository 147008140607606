import { rtkApi } from "@meetin/shared";
import { fetchFromApi } from "../api";
import { clientLogger } from "../logger";
import {
  FeatureUsage,
  Plan,
  UserStripeSubscription,
  UserSubscription,
} from "./types";
import { SupabaseClientHelper } from "../supabase";

type CreateCheckoutSessionRequest = {
  priceId: string;
};
type CreateCheckoutSessionResponse = {
  url: string;
};
type CancelPlanResponse = {
  isSuccess: boolean;
};

type UserSubscriptionsSettingsResponse = {
  subscription: UserSubscription | null;
};

type AllPlansResponse = {
  plans: Plan[];
};

type GetFeatureUsageRequest = { date: string };
type GetFeatureUsageResponse = FeatureUsage | null;

export const rtkSubscriptionApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserSubscriptionSettings: builder.query<
      UserSubscriptionsSettingsResponse,
      undefined
    >({
      providesTags: ["Subscription"],
      queryFn: async () => {
        clientLogger.info("getUserSubscriptionSettings");
        const response = await chrome.runtime.sendMessage<
          { type: string; message: unknown; from: number },
          UserSubscription | null
        >({
          type: "GET_USER_SUBSCRIPTION",
          message: {},
          from: 1,
        });

        return {
          data: { subscription: response },
        };
      },
    }),
    getUserSubscriptionDetails: builder.query<
      UserStripeSubscription,
      undefined
    >({
      providesTags: ["SubscriptionDetails"],
      queryFn: async () => {
        clientLogger.info("getUserSubscriptionDetails");
        return {
          data: await fetchFromApi(
            "get-subscription-details",
            {},
            { method: "GET" }
          ),
        };
      },
    }),
    getAllPlans: builder.query<AllPlansResponse, undefined>({
      queryFn: async () => {
        clientLogger.info("get all plans");
        return {
          data: await fetchFromApi("get-all-plans", {}, { method: "GET" }),
        };
      },
    }),
    refreshSubscription: builder.mutation<
      UserSubscriptionsSettingsResponse,
      undefined
    >({
      invalidatesTags: ["Subscription", "SubscriptionDetails"],
      queryFn: async () => {
        clientLogger.info(`refreshing subscription`);

        chrome.runtime.sendMessage({
          type: "DELETE_CACHE",
          message: "user-subscription",
        });

        const result = await fetchFromApi("refresh-subscription", {});

        return { data: { subscription: result?.subscription } };
      },
    }),
    getServerDate: builder.query<string, undefined>({
      queryFn: async () => {
        clientLogger.info(`getting server date`);

        const result = await fetchFromApi("server-date", {}, { method: "GET" });

        return { data: result?.date || new Date().toISOString() };
      },
    }),
    getFeatureUsage: builder.query<
      GetFeatureUsageResponse,
      GetFeatureUsageRequest
    >({
      queryFn: async ({ date }, api) => {
        // @ts-expect-error TODO fix this with proper type
        const userId = api.getState().app.supabaseUser?.id;

        clientLogger.info(`querying features usage ${date}`);
        const { data, error } = await SupabaseClientHelper.getSupabaseClient()
          .from("features_usage")
          .select("*")
          .eq("user_id", userId)
          .eq("date", date);

        if (error) {
          clientLogger.error(
            "failed to get feature usage",
            undefined,
            new Error(error.message)
          );

          return { data: null };
        }
        return { data: data?.[0] || null };
      },
    }),
    createCheckoutSession: builder.mutation<
      CreateCheckoutSessionResponse,
      CreateCheckoutSessionRequest
    >({
      queryFn: async ({ priceId }: CreateCheckoutSessionRequest) => {
        clientLogger.info(`creating checkout session ${priceId}`);

        const { url } = await fetchFromApi("create-checkout-session", {
          priceId,
        });

        return { data: { url } };
      },
    }),
    cancelPlan: builder.mutation<CancelPlanResponse, undefined>({
      queryFn: async () => {
        clientLogger.info(`Cancelling plan`);

        const response = await fetchFromApi("cancel-plan", {});

        return { data: response };
      },
    }),
  }),
});
export const {
  useGetUserSubscriptionDetailsQuery,
  useCancelPlanMutation,
  useGetServerDateQuery,
  useGetFeatureUsageQuery,
  useCreateCheckoutSessionMutation,
  useRefreshSubscriptionMutation,
  useGetUserSubscriptionSettingsQuery,
  useGetAllPlansQuery,
} = rtkSubscriptionApi;
