import { showErrorNotification, showInfoNotification } from "@meetin/uicore";
import { useCallback, useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetPageMarkdownQuery } from "../../ai/askLayer/rtkAskLayerQueries";
import useCollectionRelavancy from "./useCollectionRelavancy";
import {
  selectActiveCollectionId,
  selectIsPdfProcessing,
  selectMessageByRequestId,
} from "../../ai/askLayer/redux/askLayerSelectors";
import {
  removeMessage,
  setMessages,
} from "../../ai/askLayer/redux/askLayerSlice";
import {
  AiMessage,
  AskLayerQueryType,
  SearchOperationStatus,
} from "../../ai/askLayer/types";
import useEmbedding from "../../ai/askLayer/useEmbedding";
import { useCollections } from "../../collections";
import { trackEvent, AnalyticsEvents } from "../../analytics";
import { ComponentsContext } from "../../common";
import { clientLogger } from "../../logger";
import { getSupabaseErrorMessage } from "../../supabase";
import useSubscriptionLimits from "../../subscription/useSubscriptionLimits";
import useAICompletion from "./components/useAICompletion";

export const useCheckPageRelevancy = () => {
  const activeCollectionId = useSelector(selectActiveCollectionId);
  const { currentPageUrl, user } = useContext(ComponentsContext);
  const userId = user?.user_id;
  const { selectedCollection: collection } = useCollections({
    userId,
    collectionId: activeCollectionId ?? undefined,
  });

  // Summary
  const query = collection
    ? `
  I am conducting research on "${collection.title}", with the following objectives: "${collection.objective}".
  - If my research topic and/or objectives not related to the provided context or if you can't find a clear answer or if the provided context does not provide any information related to my research topic and/or objectives, your JSON response should
    - contain answer with a two line summary the provided context
    - exclude sources array
    - set the value as false for "relevant" field
  - If my research topic and/or objectives related to the context, your JSON response should contain 
    - answer with a concise, capped to 30 words, dense, and insightful summary of what the provided context is about and how it related to my research topic and/or Objectives. 
    - sources should be exactly as they appear in the context, word for word, without any substitutions, alterations, or changes in formatting. Do not change, add, or omit any word or punctuation in the quote from the context section. Do not truncate sentences. Ensure passages are chosen from different sections of the document, and avoid using titles. Each passage should be 40 words at minimum.`
    : `For the "answer" JSON field:
  - Deliver a precise, dense, 30 WORD MAX summary. Spotlight hidden insights, emphasize stats.No paraphrasing. Designed for professionals.`;
  const [isLoading, setIsLoading] = useState(false);
  const isPdfProcessing = useSelector(selectIsPdfProcessing);
  const [getPageMarkdown, { isLoading: loadingPageMarkdown }] =
    useLazyGetPageMarkdownQuery();
  const { isQuestionsLimitReached } = useSubscriptionLimits();
  const dispatch = useDispatch();
  const { onSubmit, answer, isStreaming, isFetching } = useAICompletion();
  const requestId = useRef("");
  const currentMessage = useSelector(
    selectMessageByRequestId(requestId.current)
  );

  useEffect(() => {
    if (!answer) {
      return;
    }
    dispatch(
      setMessages({
        answer: [
          {
            index: 0,
            message: {
              role: "assistant",
              content: answer,
            },
            finish_reason: "stop",
          },
        ],
        sources: answer.sources,
        requestId: requestId.current,
        // setting as completed, so streamed response will be rendered in UI
        status: SearchOperationStatus.STREAMING,
      })
    );
  }, [answer, collection?.id, currentPageUrl, dispatch]);

  const { upsertEmbedding } = useEmbedding();

  const { getKeyPoints } = useCollectionRelavancy({ collection });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkAndLoadKeyPoints = useCallback(
    (markdown: string, newInsights: AiMessage) =>
      getKeyPoints(markdown, newInsights),
    [getKeyPoints]
  );

  // need to figure out how to move this outside of here
  const checkRelevancy = useCallback(
    async (
      type:
        | AskLayerQueryType.AI_INSIGHTS
        | AskLayerQueryType.AI_INSIGHTS_ALL = AskLayerQueryType.AI_INSIGHTS,
      parentRequestId?: AiMessage["requestId"]
    ) => {
      requestId.current = `${Date.now()}`;
      if (isLoading) {
        return;
      }

      dispatch(
        setMessages({
          question:
            type === AskLayerQueryType.AI_INSIGHTS_ALL
              ? "Key Insights in this page (full page)"
              : "Key Insights in this page",
          answer: [],
          type,
          requestId: requestId.current,
          sources: [],
          url: currentPageUrl,
          collectionId: collection?.id,
          status: SearchOperationStatus.RUNNING,
          parentRequestId,
        })
      );

      if (isPdfProcessing) {
        showInfoNotification({
          message:
            "We are processing your PDF. Please try again in a few seconds.",
        });
        dispatch(removeMessage(requestId.current));
        return;
      }
      if (isQuestionsLimitReached()) {
        clientLogger.debug("Limit exhausted");
        dispatch(removeMessage(requestId.current));
        return;
      }
      setIsLoading(true);
      clientLogger.info(`timer: insights: ${requestId.current} start`);
      const { data: markdown, error } = await getPageMarkdown(currentPageUrl);
      if (!markdown || error) {
        clientLogger.error(
          "[CollectionRelavancyChecker] not able to get tab main html",
          error
        );

        trackEvent(AnalyticsEvents.NEW_INSIGHT_GENERATED, {
          success: "false",
          withinProject: `${Boolean(collection?.id)}`,
          failureReason: "not able to get tab main html",
        });

        setIsLoading(false);
        dispatch(removeMessage(requestId.current));
        showErrorNotification({
          message:
            getSupabaseErrorMessage(error) ||
            "Unable to get page content. Please refresh the tab and try again.",
        });
        return;
      }

      clientLogger.info("[CollectionRelavancyChecker] checking relavancy", {
        collection: collection?.id,
        objective: collection?.objective,
        url: currentPageUrl,
      });
      // replace all links with their text - we don't want to check relavancy of links
      const regex = /\[([\s\n]*.*?)\]\(.*?\)/g;

      const markdownWithoutLinks = markdown.replace(regex, "$1");
      clientLogger.info(
        `timer: insights: ${requestId.current} embedding start`
      );
      // Embedding is not needed for insights, so making it async
      upsertEmbedding({
        markdown,
        url: currentPageUrl,
      });

      clientLogger.info(
        `timer: insights: ${requestId.current} completion start`
      );
      onSubmit({
        query,
        markdown: markdownWithoutLinks,
        url: currentPageUrl,
        type,
      });
    },
    [
      isLoading,
      dispatch,
      currentPageUrl,
      collection?.id,
      collection?.objective,
      isPdfProcessing,
      isQuestionsLimitReached,
      getPageMarkdown,
      upsertEmbedding,
      onSubmit,
      query,
    ]
  );

  useEffect(() => {
    if (isStreaming || !currentMessage?.answer?.[0]?.message?.content?.answer) {
      return;
    }

    clientLogger.info(
      `timer: insights: ${requestId.current} completion completed`
    );
    // if ("error" in response) {
    //   const message = getSupabaseErrorMessage(response.error);

    //   clientLogger.error("[CollectionRelavancyChecker] error in onSubmit", {
    //     error: message,
    //   });

    //   trackEvent(AnalyticsEvents.NEW_INSIGHT_GENERATED, {
    //     success: "false",
    //     withinProject: `${Boolean(collection?.id)}`,
    //     failureReason: `${message}`,
    //   });

    //   setIsLoading(false);
    //   dispatch(removeMessage(requestId.current));
    //   return;
    // }

    // const sources =
    //   response.data
    //     ?.map((choice) => choice.message.content.sources)
    //     .flat()
    //     .filter((c) => Boolean(c)) || [];

    const answerFoundInPage =
      currentMessage.answer[0].message.content.answer.startsWith("Yes");

    // if (!sources && (!collection?.id || answerFoundInPage)) {
    //   checkAndLoadKeyPoints(markdownWithoutLinks, newInsights);
    // }
    dispatch(
      setMessages({
        requestId: requestId.current,
        status: SearchOperationStatus.COMPLETED,
      })
    );
    setIsLoading(false);

    trackEvent(AnalyticsEvents.NEW_INSIGHT_GENERATED, {
      success: "true",
      withinProject: `${Boolean(collection?.id)}`,
      answerFound: `${answerFoundInPage}`,
    });
  }, [
    isLoading,
    isPdfProcessing,
    isQuestionsLimitReached,
    getPageMarkdown,
    currentPageUrl,
    collection?.id,
    collection?.objective,
    upsertEmbedding,
    onSubmit,
    query,
    dispatch,
    checkAndLoadKeyPoints,
    isStreaming,
    currentMessage?.answer,
    currentMessage?.parentRequestId,
  ]);

  return {
    checkRelevancy,
    isLoading: isFetching || isLoading || loadingPageMarkdown,
  };
};
