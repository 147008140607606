import { rtkApi } from "@meetin/shared";
import { showErrorNotification } from "@meetin/uicore";
import {
  AiMessage,
  AskLayerQueryType,
  AskLayerResponse,
  SERPApiResponse,
} from "./types";
import { getPdfContent } from "./pdfUtils";
import { getActiveTabHTMLMarkdown } from "./utils";
import { clientLogger } from "../../logger";
import { SupabaseClientHelper } from "../../supabase";
import {
  ANONYMOUS_USER_ID,
  isSidePanel,
  sendMessageToCurrentTab,
} from "../../app";
import { AskLayerInitialState } from "./redux/askLayerSlice";
import { incrementAnonymousChatUsage } from "../../anonymous/settings";
import { selectCurrentUser } from "../../user";
import { fetchFromApi } from "../../api";
import { getOGDataFromTab } from "../../posts";

export type AskLayerQueryProps = {
  query: string;
  markdown: string;
  promptText?: string;
  url: string;
  type: AskLayerQueryType;
};

type UpsertEmbeddingRequest = {
  markdown: string;
  url: string;
};
type UpsertEmbeddingResponse = {
  isSuccess: boolean;
};

type MarkdownFromCrawlerResponse = Record<string, string>;
type MarkdownFromCrawlerRequest = { urls: string[] };

type SERPRequest = {
  url: string;
  query: string;
  requestId: AiMessage["requestId"];
};

/**
 * Ask Layer related supabase queries
 */

export const rtkAskLayerApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    // get search engine results
    getSERPResults: builder.query<SERPApiResponse, SERPRequest>({
      providesTags: (result, error, arg) => [
        { type: "getSERPResults", id: arg.requestId },
      ],
      queryFn: async ({ url, query }: SERPRequest) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();

        clientLogger.info("getSERPResults", { url, query });
        const { data, error } = await supabaseClient.functions.invoke(
          "ask-layer",
          {
            body: {
              url,
              query,
              promptText: `You are a very enthusiastic Layer app representative who loves to help people! In my website, user is asking a question: "${query}". Can you modify this question to make it more search engine friendly. You are not required to find answer for the user's question. Just modify the question to make it more search engine friendly.`,
              type: AskLayerQueryType.GET_SERP_RESULTS,
            },
          }
        );

        if (error) {
          clientLogger.error(`could not complete getSERPResults`, {
            error: error.message,
          });
          showErrorNotification({
            message: error.message,
          });
          throw new Error(error.message);
        }

        clientLogger.info("got SERPResults", { url, query });
        return { data };
      },
    }),
    getMarkdownFromCrawler: builder.query<
      MarkdownFromCrawlerResponse,
      MarkdownFromCrawlerRequest
    >({
      queryFn: async (queryData: MarkdownFromCrawlerRequest) => {
        // TODO: does not need to be a mutation and this should be in backend so that we dont expose this token
        const apifyToken = "apify_api_x8SDctO7Dbj5TssVTFyGhvxuramjDt0QOatd";

        if (!apifyToken) {
          throw new Error("Missing apifyToken");
        }

        clientLogger.info(`crawling ${queryData.urls}`);
        const response = await fetch(
          `https://api.apify.com/v2/acts/apify~website-content-crawler/run-sync-get-dataset-items?token=${apifyToken}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              aggressivePrune: false,
              clickElementsCssSelector: '[aria-expanded="false"]',
              crawlerType: "playwright:firefox",
              debugMode: false,
              maxCrawlDepth: 0,
              proxyConfiguration: {
                useApifyProxy: true,
              },
              removeCookieWarnings: true,
              removeElementsCssSelector:
                'nav, footer, script, style, noscript, svg,\n[role="alert"],\n[role="banner"],\n[role="dialog"],\n[role="alertdialog"],\n[role="region"][aria-label*="skip" i],\n[aria-modal="true"]',
              saveFiles: false,
              saveHtml: false,
              saveMarkdown: true,
              saveScreenshots: false,
              startUrls: queryData.urls.map((url) => ({ url })),
            }),
          }
        );
        if (!response.ok) {
          throw new Error(response.statusText || "unknown error");
        }
        const data = await response.json();
        return {
          data: data.reduce(
            (
              acc: Record<string, string>,
              urlResponse: { url: string; markdown: string }
            ) => ({
              ...acc,
              [urlResponse.url]: urlResponse.markdown as string,
            }),
            {}
          ),
        };
      },
    }),
    getPageMarkdown: builder.query<string | null, string>({
      // Url param is used for caching
      queryFn: async (_url, api) => {
        clientLogger.info("getPageMarkdown");
        // In web, we store the markdown when file is selected
        const pdfMarkdown = (
          api.getState() as { askLayer: AskLayerInitialState }
        ).askLayer.markdown;

        if (pdfMarkdown) {
          return { data: pdfMarkdown };
        }
        if (isSidePanel()) {
          const mimeType = (await sendMessageToCurrentTab({
            type: "SELECT_DOCUMENT_MIME_TYPE",
            message: {},
            from: 0,
          })) as null | {
            mimeType: string;
            url: string;
          };
          if (mimeType?.mimeType === "application/pdf") {
            const markdown = await getPdfContent(mimeType.url);
            return { data: markdown };
          }
        }

        return { data: await getActiveTabHTMLMarkdown() };
      },
    }),
    upsertEmbedding: builder.mutation<
      UpsertEmbeddingResponse,
      UpsertEmbeddingRequest
    >({
      queryFn: async (queryData: UpsertEmbeddingRequest) => {
        clientLogger.info("upsert embedding query data", {
          queryData: { ...queryData, markdown: queryData.markdown.length },
        });
        const data = await fetchFromApi("embedding", queryData);

        return { data: data || { isSuccess: false } };
      },
    }),
    askLayer: builder.query<AskLayerResponse, AskLayerQueryProps>({
      queryFn: async (queryData: AskLayerQueryProps, api) => {
        clientLogger.info("upsert embedding query data", { queryData });

        const ogData = await getOGDataFromTab();

        const [data] = await Promise.all([
          fetchFromApi("completion", queryData),
          fetchFromApi("save-og-data", { data: ogData }),
        ]);
        if (!data) {
          return { data: null };
        }
        // @ts-expect-error TODO: set correct type for getstate
        const userId = selectCurrentUser(api.getState())?.data?.user_id;
        if (
          userId === ANONYMOUS_USER_ID &&
          (queryData.type === AskLayerQueryType.AI_SEARCH ||
            queryData.type === AskLayerQueryType.AI_INSIGHTS)
        ) {
          incrementAnonymousChatUsage();
        }

        return { data: data.choices };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetMarkdownFromCrawlerQuery,
  useLazyAskLayerQuery,
  useLazyGetSERPResultsQuery,
  useUpsertEmbeddingMutation,
  useLazyGetPageMarkdownQuery,
} = rtkAskLayerApi;
