import { useEffect, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { Profile, SupabaseUser } from "@meetin/shared";
import { initUser } from "../logger/initUser";
import { initRumConfig } from "./rumConfig";
import { EnvironmentTypes } from "../logger";

export const useMonitoring = ({
  user,
  supabaseUser,
  ...rest
}: {
  user: Profile | null;
  supabaseUser: SupabaseUser | null;
} & Parameters<typeof initRumConfig>["0"]): void => {
  const isDevEnv = rest.env === EnvironmentTypes.Dev;

  const [isInit, setInit] = useState(
    Boolean(datadogRum.getInternalContext()?.session_id)
  );

  useEffect(() => {
    if (isInit || isDevEnv || !user || !supabaseUser) {
      return;
    }

    // https://linear.app/layer-app/issue/DEV-592/datadog-rum-session
    // datadogRum.init(initRumConfig(rest));

    initUser(user, supabaseUser);

    setInit(true);
  }, [user, isDevEnv, isInit, supabaseUser, rest]);
};
