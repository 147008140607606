import { Box } from "@meetin/uicore";

const FavIcon = ({ url }: { url: string }) => (
  <Box
    sx={{
      background: `url(${url}) no-repeat center center`,
      backgroundSize: "contain",
      minWidth: 22,
      mr: 0.875,
      borderRadius: "3px",
    }}
    aria-label={`${url} icon`}
  />
);

export default FavIcon;
